<template>
  <div class="areaAppoint">
    <!-- banner -->
    <div class="areaAppoint-t">
      <img src="./img/banner.png" alt="" />
    </div>
    <!-- 列表 -->
    <div class="oList">
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        ref="list"
        :finishedText="finishedText"
      >
        <div
          v-for="(item, index) in listData"
          :key="index"
          class="areaAppointItem"
          @click="toDetail(item)"
        >
          <div class="areaAppointItem-t">
            <div class="areaAppointItem-tl">
              <img :src="item.picUrl" alt="" />
            </div>
            <div class="areaAppointItem-tr">
              <div class="name">{{ item.name }}</div>
              <div class="num">
                <div class="num-l">{{ item.area }}㎡</div>
              </div>
            </div>
          </div>
          <div class="areaAppointItem-br">立即预定</div>
          <div class="historyBox">
            <div class="box">
              <img src="./img/history.png" alt="" />
              <div class="num">{{ item.totalDegree }}次</div>
            </div>
          </div>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { getAreaListUrl, getStreetCommunityUrl } from "./api.js";
import { gloabalCount } from "@/utils/common";
var moment = require("moment");
export default {
  name: "areaAppoint",
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      loading: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
        date: moment(new Date()).format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    // this.getPlaceList();
  },
  mounted() {
    gloabalCount("", 5, 1);
    if (this.userId != "0") {
      // this.getCommomPlaceList();
    }
  },
  methods: {
    onLoad() {
      this.getActivityList();
    },
    // 获取列表数据
    getActivityList() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.houseId || this.communityId || undefined,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        date: this.requestData.date,
        // minCount: this.requestData.minCount,
        // maxCount: this.requestData.maxCount,
        // typeId: this.requestData.typeId,
        // districtCode: this.requestData.districtCode,
        // streetId: this.requestData.streetId,
        // structuringId: this.requestData.structuringId,
        districtCode: "330212",
        streetId: "330212015000",
        structuringId: "330212015002",
      };
      this.$axios
        .get(`${getAreaListUrl}`, {
          params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              if (item.picUrl && item.picUrl.length > 0) {
                item.picUrl = this.$handleImg(400, 400, item.picUrl);
              }
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    toDetail(item) {
      this.$router.push({
        name: "dayCareDetail",
        query: {
          placeId: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.areaAppoint {
  box-sizing: border-box;
  width: 100vw;
  min-height: 100vh;
  background: #f7f7f7;
  padding: 24px 30px;
  .areaAppoint-t {
    width: 100%;
    height: 310px;
    margin-bottom: 12px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .areaAppointItem {
    height: 220px;
    background: #fff;
    border-radius: 20px;
    margin: 30px 0;
    box-shadow: 0px 0px 50px 4px #f7f7f7;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    .historyBox {
      position: absolute;
      top: 50px;
      right: 32px;
      color: rgba(0, 0, 0, 0.5);
      font-size: 24px;

      .box {
        display: flex;
        align-items: center;

        img {
          width: 28px;
          height: 24px;
          margin-right: 4px;
        }
      }
    }

    .areaAppointItem-t {
      display: flex;
      height: 220px;
      box-sizing: border-box;

      .areaAppointItem-tl {
        width: 224px;
        height: 100%;
        margin-right: 22px;
        box-sizing: border-box;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .areaAppointItem-tr {
        height: 100%;
        flex: 1;
        font-size: 30px;
        box-sizing: border-box;
        position: relative;

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 300px;
          font-size: 32px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          box-sizing: border-box;
          position: absolute;
          left: 0;
          top: 38px;
        }

        .num {
          display: flex;
          color: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 90px;
          left: 0;

          .num-l {
            margin-right: 10px;
          }

          .num-r {
            margin-left: 10px;
          }
        }

        .type {
          color: rgba(0, 0, 0, 0.5);
          color: #999;
          position: absolute;
          left: 0;
          bottom: 14px;
        }
      }
    }

    .areaAppointItem-br {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 194px;
      height: 62px;
      background: linear-gradient(180deg, #ffd67b 0%, #ffbe2b 98%);
      color: #fff;
      text-align: center;
      font-size: 26px;
      line-height: 62px;
      border-radius: 16px 0px 16px 0px;
    }
  }
}
</style>
